.slider{
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    
    .public__title{
        color: var(--secondary-dark);
        font-size: 6rem!important;
        width: fit-content;
        position: absolute;
        margin: auto;
        left:0;
        right: 0;
        top: 10px;
        z-index: 100;
        font-size: 3rem;
      }
    
    .slider__container{
        width: 100%;
        height: 100%;
        display: flex;
        
        .slider_img{
            width: 100%;
            height: 99%;
            object-fit: cover;
            transition: all 1s ease;
        }
         
    
    }

    .icons__container{
        display: block;
        width: 100%;
        position: absolute;
        bottom: 50%;
        margin: auto;
        .icons{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            .icon{
                width: 50px;
                height: 50px;
                font-weight: bolder;
                font-size: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                color: var(--primary-dark);
                background-color: var(--secondary-light);
                cursor: pointer;
                opacity:0.6;
            }
        
        }
    }
}






  @media screen and (max-width : 700px){
    .slider{
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
        
        .public__title{
            font-size: 3rem!important;
            width: fit-content;
            position: absolute;
            margin: auto;
            left:0;
            right: 0;
            top: 10px;
            z-index: 100;
          }
        
        .slider__container{
            width: 100%;
            height: 100%;
            display: flex;
            
            .slider_img{
                object-fit: cover;
                transition: all 1s ease;
            }
             
        
        }
    
        .icons__container{
            display: block;
            width: 100%;
            position: absolute;
            bottom: 50%;
            margin: auto;
            .icons{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                
                .icon{
                    width: 25px;
                    height: 25px;
                    font-weight: bolder;
                    font-size: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    color: var(--primary-dark);
                    background-color: var(--secondary-light);
                    opacity: 0.6;
                    cursor: pointer;
                }
            
            }
        }
    }

  }