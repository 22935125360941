@import url('https://fonts.googleapis.com/css2?family=Allura&family=Macondo&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.light {

  --background-paper: #fff;
  --background-paper1: rgb(230, 218, 218);
  --background-paper2: rgb(217, 200, 200);
  --primary-main:#1976d2;
  --primary-light:#42a5f5;
  --primary-dark:#1565c0;
  --primary-contrast-text : #fff;
  --secondary-main: #9c27b0;
  --secondary-light: #ba68c8;
  --secondary-dark : #7b1fa2;
  --secondary-contrast-text : #fff;
  --error-main : #d32f2f;
  --error-light: #ef5350;
  --error-dark: #c62828;
  --error-contrast-text: #fff;
  --warning-main: #ed6c02;
  --warning-light: #ff9800;
  --warning-dark: #e65100;
  --warning-contrast-text: #fff;
  --info-main: #0288d1;
  --info-light: #03a9f4;
  --info-dark: #01579b;
  --info-contast-text: #fff;
  --success-main: #037f13;
  --success-light: #4caf50;
  --success-dark : #1b5e20;
  --success-contrast-text: #fff;
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500:#9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;
  --text-primay : rgba(0, 0, 0, 0.87);
  --text-secondary : rgba(0, 0, 0, 0.6);
  --text-disabled: rgba(0, 0, 0, 0.38);
  --action-active: rgba(0, 0, 0, 0.54);
  --action-hover: rgba(0, 0, 0, 0.04);
  --action-hover-opacity: 0.04;
  --action-selected: rgba(0, 0, 0, 0.08);
  --action-disabled: rgba(0, 0, 0, 0.26);
  --action-disabled-background: rgba(0, 0, 0, 0.12);
  --action-focus: rgba(0, 0, 0, 0.12);
  --shadow-0: none;
  --shadow-1: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  --shadow-2: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  --shadow-5: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
  --shadow-10: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
  --shadow-15: 0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12); 
  --shadow-20: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12);
  --shadow-24: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans", sans-serif, "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,"Liberation Mono","Courier New", monospace;


  
  --BG-CONTAINER-COLOR:#d4e7f8;
  --BG-HEADER-COLOR: #bdd8f4;
  --BG-FOOTER-COLOR:#bdd8f4;
  --BORDER-COLOR: rgb(15, 13, 13);
  
  --COLOR: #666666;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: rgb(11, 11, 209);
  --LINK-HOVER: #6366f1;
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: rgb(165, 215, 253);
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #F00;
  --BUTTON-DANGER:  #F00;
 
  --BUTTTON-WARNING: #5f5014;
  --BUTTON-PRIMARY: #4a44f5;
  
}

.dark {
  
  --background-paper: #2b2b2b;
  --background-paper1: rgb(23, 22, 22);
  --background-paper2: rgb(44, 41, 41);
  --primary-main:#90caf9;
  --primary-light:#e3f2fd;
  --primary-dark:#42a5f5;
  --primary-contrast-text : rgba(0, 0, 0, 0.87);
  --secondary-main: #ce93d8;
  --secondary-light: #f3e5f5;
  --secondary-dark : #ab47bc;
  --secondary-contrast-text : rgba(0, 0, 0, 0.87);
  --error-main : #f44336;
  --error-light: #e57373;
  --error-dark: #d32f2f;
  --error-contrast-text: #fff;
  --warning-main: #ffa726;
  --warning-light: #ffb74d;
  --warning-dark: #f57c00;
  --warning-contrast-text: rgba(0, 0, 0, 0.87);
  --info-main: #29b6f6;
  --info-light: #4fc3f7;
  --info-dark: #0288d1;
  --info-contast-text: rgba(0, 0, 0, 0.87);
  --success-main: #66bb6a;
  --success-light: #81c784;
  --success-dark : #388e3c;
  --success-contrast-text: rgba(0, 0, 0, 0.87);
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500:#9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;
  --text-primary : #fff;
  --text-secondary : rgba(255, 255, 255, 0.7);
  --text-icon: rgba(255, 255, 255, 0.5);
  --text-disabled: rgba(255, 255, 255, 0.5);
  --action-active: #fff;
  --action-hover: rgba(255, 255, 255, 0.08);
  --action-hover-opacity: 0.16;
  --action-selected: rgba(255, 255, 255, 0.16);
  --action-disabled: rgba(255, 255, 255, 0.3);
  --action-focus: rgba(255, 255, 255, 0.12);
  --shadow-0: none;
  --shadow-1: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  --shadow-2: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  --shadow-5: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
  --shadow-10: 0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12);
  --shadow-15: 0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12); 
  --shadow-20: 0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12);
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans", sans-serif, "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,"Liberation Mono","Courier New", monospace;
  
  --BG-HEADER-COLOR: #31363b;
  --BG-FOOTER-COLOR:#393b3d;
  --BORDER-COLOR: rgb(210, 197, 197);
   
  --COLOR: hsla(0, 100%, 100%, 0.87);
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: rgb(192, 192, 241);
  --LINK-HOVER: #0069d9;
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: rgb(49, 53, 57);
  --TABLE-COLOR: rgb(239, 219, 219);
  --TABLE-HOVER-COLOR: rgb(211, 214, 24) ;
  --TABLE-HOVER-BGCOLOR: rgb(238, 14, 14);
  --INPUT-INVALID: #F00;
  --BUTTON-DANGER:  #F00;
  --BUTTON-SUCCESS: #037f13;
  --BUTTTON-WARNING: #5f5014;
  --BUTTON-PRIMARY: #0069d9;
  
}


.container{
  color: var(--text-primary);
  background-color: var(--background-paper);
  font-family: sans-serif;
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items:center;

  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    width: 100%;
  }
}


.header{
  width: 100%;
  box-shadow: var(--shadow-1);
  z-index: 1;
  padding: 0 0.5em;
  margin-bottom: 1rem;

  .header__container{
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .header__title{
      letter-spacing: .1em;
      color: inherit;
      font-size: 3.5rem;
      font-weight: 900;
    }

    .header__nav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      margin-right: 20px;
      gap: 1em;
    }
  }
}
.footer {
  width: 100%;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  z-index: 1;
  
  .footer-wrapper{
    display: flex;
    padding: 10px;
    width: 95%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 1rem;

    .footer_left{
      flex: 1;
      a {
        text-decoration: none;
        p{
          font-size: 1rem;
          color: var(--primary-dark);
          font-style: normal;
        }
      }
    } 
    .footer_middle{
      flex: 1;
      display: flex;
      justify-content: center;
      text-align: center;
      
      a{
        text-decoration: none;
        font-size: 1rem;
        color: var(--primary-dark);
        &:hover{
          color: var(--secondary-dark);
        }
        
      }
      
      
      h2{
        font-size: 2rem;
      }
    
    }
    .footer_right{
      display: flex;
      justify-content: center;
      flex: 1;
      a{
        text-decoration: none;
        cursor: pointer;
        color: var(--primary-dark);
        padding: 10px;
        border-radius: 10px;
        font-family: 'Montserrat', sans-serif!important;
        font-style: normal!important;
        font-size: 1rem;
        &:hover{
          color: var(--secondary-dark);
        }
      }
      
    
    }
  }
  .dash {
    display: flex;
    padding: 10px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 1rem;
    .footer_left{
      display: flex;
      justify-content: flex-start;
      a {
        text-decoration: none;
        p{
          font-family: 'Montserrat', sans-serif!important;
          font-style: normal!important;
          font-size: 1rem;
          text-align: center;
        }
      }
    } 
    .footer_middle{
      display: flex;
      justify-content: center;
      text-align: center;
      flex: 3;
      a{
        font-size: 1rem;
      }
      h2{
        
        font-size: 1.5rem;
        
      }
    
    }
    .footer_right{
      display:flex ;
      justify-content: flex-end;
      
      a{
        font-family: 'Montserrat', sans-serif!important;
        font-style: normal!important;
        font-size: 1rem;
        
      }
    
    }
  
  }
  
  
}

/* || VARIABLES */
html {
  font-size: 16px;
}
h1{
  text-align: center;
  font-family: Allura, cursive;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.167;
  letter-spacing: -0.01562em;
}
h2{
  font-family: Allura, cursive;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}

h3 {
  font-family: Allura, cursive;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
}

.subtitle1{
  font-family: Allura, cursive;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;

}

.subtitle2 {
  font-family: Allura, cursive;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 0.00714em;

}

body {
  display: block;
  font-family: 'Poppins', sans-serif;
  
  
}

.body1{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.body2{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;

}


.public {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.welcome {
	margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
	a {
		&::before {
			content: "➜ ";
		}
	}

  .welcome-link{
    color: var(--primary-dark);
    font-family: Allura, cursive;
    text-decoration: none;
    font-size: 2.3rem;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--shadow-5);
    &:hover{
      color:var(--primary-light)

    }
  }
  
}



input[type="text"]{
  background-color: var(--grey-200);
  overflow: visible;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--grey-200) inset !important;
}
button,
textarea,
select {
  font: inherit;

}



.logo__text{
  color: var(--primary-main);
  text-decoration: none;
  padding: 10px;
  &:hover{
    color:var(--primary-light)
  }
  
}


/* || UTILITY CLASSES */
.errmsg {
  display: inline-block;
  background-color: var(--error-light);
  color: var(--error-contrast-text);
  padding: 0.25em;
  margin-bottom: 0.5em;

  .errmsg a:any-link {
    text-decoration: underline;
  }
}

.icon-button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: grid;
  color: var(--primary-main);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

    &:hover,
    &:focus-visible {
     transform: scale(0.9);
      }

    &[disabled] {
     visibility: hidden;
     }
}

.success__button{
  color: var(--success-main);
}

.warning__button{
  color: var(--warning-dark);
}

.danger__button{
  color: var(--error-dark);
  border: none;
}

.button-basic{
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  cursor: pointer;

}
.button__danger{
  @extend .button-basic;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  &:hover{
    background-color: #760510d6;
  }
}


.button__success{
  @extend .button-basic;
  color: #fff;
  background-color: #149523;
  border-color: #0d7232;  
  &:hover{
    background-color: #035515d6;
  }
}


.button__warning{
  @extend .button-basic;
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  &:hover{
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
}


.button__primary{
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  &:hover{
    cursor: pointer;
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
    
}
.btn{
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



.sistem_giris{
  font-size: 1.5rem;
}


/* || DASH HEADER, CONTAINER & FOOTER */
.offscreen{
  display: none;
  width: 100%;
}


/* || TABLES - GENERAL */

thead,
tbody,
tr{
  display: contents;  
}


tr:nth-child(odd) td {
  background-color: var(--grey-200);
  color:black;
}
tr:nth-child(even) td {
  background-color: var(--grey-100);
  color:black;
}

.table tr:hover td {
  background-color: var(--grey-200);
  color:black;
}


th, td{
  border: 0.1px solid var(--grey-500);
  display: flex;
  justify-content: center;
  align-items: center;
}
th{
  background-color: var(--white);
  color: var(--black);
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px;
}
td{
  font-size: 1.1rem;
  font-weight: 300;
  padding-top: 3px;
  padding-bottom: 3px;
}

.table-th__button{
  flex-basis: auto
}

.user-table{
  margin-top: 25px;
}

.table {
  padding: 20px;
  margin: 0 auto;
  font-size: 1rem;
  width:99%;
  display: grid;
  border-spacing: 0;
  box-shadow: var(--shadow-15);
  grid-template-columns: 1fr fit-content(100%);
  white-space: wrap;

  .table__thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  
  
  .table__cell {  
    text-align: center;
    overflow: hidden;
  }

  .table__cell--inactive {
    background-color: var(--error-light);
  }

  .table__button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    &:hover{
      color:red;
    }
  }
 
}
.table-public_notes{
  grid-template-columns: repeat(4, auto) ;
}
.table_users {  
  width: 900px;
  grid-template-columns: repeat(3, 1fr);  
}

.rapor-table-yonetici {
  grid-template-columns: repeat(12, auto) ;

}
.rapor-table-calisan {
  grid-template-columns: repeat(10, auto) ;

}

.table-yonetici {
  grid-template-columns: repeat(10, auto) ;
}

.table-calisan {
  grid-template-columns: repeat(8, auto) ;
}
.table-memur {
  grid-template-columns: repeat(8, auto) ;
}


.search-pagination{
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  gap:35px;
}
.iteration{
  display: flex;
  flex-direction: row;
  width: fit-content;
  button {height: 45px; width: 45px};
    p{
      height: 45px; width: 45px; text-align: center; line-height: 2.3; margin-left: 5px; margin-right: 5px;
    }
}



/* || FORM - GENERAL */
.form_wrapper{
  display: flex;
  padding: 20px;
  margin-top: 35px;
  flex-direction: column;
  width: 700px;
  box-shadow: var(--shadow-10);

  .form__title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    h2{
      font-size: 2.5rem;
    }

    .form__action-buttons-wrapper{
      position: relative;
      .form__action-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 0.5em;
      }
      .form__button{
        font-size: 1.9rem;
        background-color: transparent;
        border: none;
        display: grid;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

        &:hover,
        &:focus-visible {
        transform: scale(0.9);
          }

        &[disabled] {
        visibility: hidden;
        }
          }
        }
    
  }
}

.form__login {
  display: flex;
  width: 400px;
  flex-flow: column nowrap;
}

.form__checkbox-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5em;
}

.form__persist {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5em;
}


.form-group{
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.form__input {
    width: 100%;
    display: block;
    margin: 0 0 15px 0;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color:var(--grey-200);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    box-shadow: var(--shadow-1);
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form__input--incomplete {
  border: 1px solid var(--error-main);
  outline: 1px solid var(--error-light);
}

.form__checkbox {
  width: 24px;
  height: 24px;
}

.form__select {
  margin-left: 10px;
  width: 130px;
  padding: 0.25em;
  overflow: hidden;
}




.form__label{
  color: var(--primary-light);
  font-weight: 500;
}
.search-count{
  width: 100%;
  display: flex;
  margin: 15px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}




@media screen and (max-width : 700px){

  .wrapper{
    width: 95%;
    .inner_wrapper{
      width: 90%;
    }
  }

  .header{
    width: 100%;
    box-shadow: none;
    z-index: 1;
    padding: 0 0.5em;
  
    .header__container{
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
  
      .header__title{
        letter-spacing: .1em;
        color: inherit;
        font-size: 1.9rem;
        font-weight: 900;
        border-bottom: 2px solid var(--secondary-dark);
      }
  
      .header__nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
  
      }
    }
  }
    
  
  .footer { 
    display: flex;
    justify-content: center;
    
    .footer-wrapper{
      display: flex;
      width: 95%;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      align-content: center;
  
      .footer_left{
        a {
          text-decoration: none;
          p{
            font-family: 'Montserrat', sans-serif!important;
            font-style: normal!important;
            font-size: 0.9rem;
            text-align: center;
          }
        }
        
      } 
      .footer_middle{
        display: flex;
        justify-content: center;
        text-align: center;
        a{
          font-size: 0.9rem;
        }
        h2{
          
          font-size: 1.5rem;
          
        }
      
      }
      .footer_right{
        
        a{
          font-family: 'Montserrat', sans-serif!important;
          font-style: normal!important;
          font-size: 0.9rem;
          
        }
      
      }
    }
    .dash {
      display: flex;
      padding: 10px;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 1rem;
      .footer_left{
        a {
          text-decoration: none;
          p{
            font-family: 'Montserrat', sans-serif!important;
            font-style: normal!important;
            font-size: 0.9rem;
            text-align: center;
          }
        }
        
      } 
      .footer_middle{
        display: flex;
        justify-content: center;
        text-align: center;
        flex: 3;
        a{
          font-size: 1rem;
        }
        h2{
          
          font-size: 1.5rem;
          
        }
      
      }
      .footer_right{
        display:flex ;
        justify-content: flex-end;
        
        a{
          font-family: 'Montserrat', sans-serif!important;
          font-style: normal!important;
          font-size: 1rem;
          
        }
      
      }
    
    }
  }
  
  
  
  /* || VARIABLES */
  html {
    font-size: 16px;
  }
  h1{
    text-align: center;
    font-family: Allura, cursive;
    font-size: 1.9rem;
    text-align: center;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: -0.01562em;
  }
  h2{
    font-family: Allura, cursive;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.00833em;
  }
  
  h3 {
    font-family: Allura, cursive;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  
  .subtitle1{
    font-family: Allura, cursive;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
  
  }
  
  .subtitle2 {
    font-family: Allura, cursive;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: 0.00714em;
  
  }
  
  body {
    display: block;
    font-family: 'Poppins', sans-serif;    
    
  }
  
  .body1{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
  
  .body2{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  
  }
  
  
  .public {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .welcome {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a {
      &::before {
        content: "➜ ";
      }
    }
  
    .welcome-link{
      color: var(--primary-dark);
      font-family: Allura, cursive;
      text-decoration: none;
      font-size: 1.7rem;
      padding: 5px;
      border-radius: 10px;
      box-shadow: var(--shadow-5);
      &:hover{
        color:var(--primary-light)
  
      }
    }
    
  }
  

  input[type="text"]{
    background-color: none;
    overflow: visible;
  }
  button,
  textarea,
  select {
    font: inherit;
  
  }
  
  
  
  .logo__text{
    color: var(--primary-main);
    text-decoration: none;
    padding: 10px;
    &:hover{
      color:var(--primary-light)
    }
    
  }
  
  
  /* || UTILITY CLASSES */
  .errmsg {
    display: inline-block;
    background-color: var(--error-light);
    color: var(--error-contrast-text);
    padding: 0.25em;
    margin-bottom: 0.5em;
  
    .errmsg a:any-link {
      text-decoration: underline;
    }
  }
  
  
  .icon-button {
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    display: grid;
    color: var(--primary-main);
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  
      &:hover,
      &:focus-visible {
       transform: scale(1.2);
        }
  
      &[disabled] {
       visibility: hidden;
       }
  }
  
  
  
  
  .button-basic{
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    cursor: pointer;
  
  }
  .button__danger{
    @extend .button-basic;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    &:hover{
      background-color: #760510d6;
    }
  }
  
  
  .button__success{
    @extend .button-basic;
    color: #fff;
    background-color: #149523;
    border-color: #0d7232;  
    &:hover{
      background-color: #035515d6;
    }
  }
  
  
  .button__warning{
    @extend .button-basic;
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    &:hover{
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
    }
  }
  
  
  .button__primary{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    &:hover{
      cursor: pointer;
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }
      
  }

  
  
  
  .sistem_giris{
    font-size: 1.5rem;
  }
  
  
  /* || DASH HEADER, CONTAINER & FOOTER */
  .offscreen{
    display: none;
    width: 100%;
  }
  
  
  /* || TABLES - GENERAL */
  
  thead,
  tbody,
  tr{
    display: contents;  
  }
  
  
  tr:nth-child(odd) td {
    
  }
  tr:nth-child(even) td {
    
  }
  
  .table tr:hover td {
    background-color: var(--grey-200);
    color:black;
  }
  
  
  th, td{
    border: 0.1px solid var(--grey-500);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  th{
    background-color: var(--white);
    color: var(--black);
    font-size: 1rem;
    font-weight: 600;
    
  }
  td{
    font-size: 0.9rem;
    font-weight: 300;
  }

  
  
  .user-table{
    width: 100%;
    margin-top: 25px;
    
    .table_users {  
      width: 95%;
      grid-template-columns: repeat(3, 1fr);  
    }
  }
  
  .table {
    padding: 5px;
    margin: 0 auto;
    width: 99%;
    font-size: 0.7rem;
    display: grid;
    border-spacing: 0;
    box-shadow: var(--shadow-15);
    grid-template-columns: 1fr fit-content(100%);
    white-space: wrap;
    .table__thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }  
    
    .table__cell {  
      text-align: center;
      padding: 0px;
      width: auto;
      height: auto;
      overflow: hidden;
      word-wrap: break-word;
     
      
      
    }
      
    .table__cell--inactive {
      background-color: var(--error-light);
    }
    
    .table-th__button{
      flex-basis: auto
    }
   
  }
  .table-public_notes{
    grid-template-columns: repeat(3, auto) ;
  }
  
  
  .rapor-table-calisan {
    grid-template-columns: repeat(4, auto) ;
  
  }
  .rapor-table-yonetici {
    grid-template-columns: repeat(5, auto) ;
  
  }
  
  .table-yonetici {
    grid-template-columns: repeat(2, auto) repeat(2, auto) ;
  }
  
  .table-calisan {
    grid-template-columns: repeat(2, auto) repeat(1, auto) ;
  }
  .table-memur {
    grid-template-columns: repeat(2, auto) repeat(1, auto) ;
  }
  .mobile{
    display: none;
  }
  
  .table__button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    &:hover{
      color:red;
    }
  }
  
  
  .search-pagination{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    gap:35px;
  }
  .iteration{
    display: flex;
    flex-direction: row;
    width: fit-content;
    button {height: 25px; width: 25px};
    p{
      height: 25px; width: 25px; text-align: center; line-height: 1.3; margin-left: 5px; margin-right: 5px;
    }
  }
   
  
  /* || FORM - GENERAL */
  .form_wrapper{
    display: flex;
    padding: 20px;
    margin-top: 15px;
    flex-direction: column;
    width: 95%;
    box-shadow: var(--shadow-10);
  
    .form__title-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      h2{
        font-size: 2rem;
      }
  
      .form__action-buttons-wrapper{
        position: relative;
        .form__action-buttons {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          gap: 0.5em;
          .form__button{
            font-size: 1.6rem;
            background-color: transparent;
            border: none;
            display: grid;
            filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    
            &:hover,
            &:focus-visible {
            transform: scale(0.9);
              }
    
            &[disabled] {
            visibility: hidden;
            }
              }
        }
      }
      
    }
  }
  
  .form__login {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    
    
  }
  
  .form__checkbox-container {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.5em;
  }
  
  .form__persist {
    margin-top: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5em;
  }
  

  
  .form__input {
      width: 100%;
      display: block;
      margin: 0 0 15px 0;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: var(--grey-200);
      background-clip: padding-box;
      border: 1px solid #ced4da;
      box-shadow: var(--shadow-1);
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  
  .form__input--incomplete {
    border: 1px solid var(--error-main);
    outline: 1px solid var(--error-light);
  }
  
  .form__checkbox {
    width: 24px;
    height: 24px;
  }
  
  .form__select {
    width: 130px;
    padding: 0.25em;
    overflow: hidden;
  }
  
  .search-count{
    width: 100%;
    display: flex;
    margin: 15px 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  
 
}
